import PageHead from "../Head";
import Link from "next/link";
import MobileMenu from "@/components/Header/MobileMenu";
import HeaderStyleNine from "@/components/Header/HeaderStyle-Nine";
import HeaderTopBar from "@/components/Header/HeaderTopBar/HeaderTopBar";
import FooterThree from "@/components/Footer/Footer-Three";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useState } from "react";
import { useLogin } from "@/hooks/useLogin";
import Spinner from "@/components/Spinner";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

const validation = Yup.object().shape({
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  checkbox: Yup.string().required("Required"),
});

const Account = () => {
  const { flow, submitFlow, csrf_token, loginLoading, loginMessage } =
    useLogin();
  const [inputType, setInputType] = useState("password");
  const [isActive, setIsActive] = useState(false);

  // Submit
  const handleSubmit = async (values) => {
    try {
      await submitFlow({
        csrf_token,
        identifier: values?.email,
        method: "password",
        password: values?.password,
      });
    } catch (error) {
      toast.error(`${error?.message}`);
      console.log(error.message);
    }
  };

  const handlePassword = (e) => {
    if (setIsActive(!isActive)) {
      setInputType(inputType === "password" ? "text" : "password");
    } else {
      setInputType(inputType === "text" ? "password" : "text");
    }
  };

  return (
    <>
      <PageHead title="Mentorbliss - New learning platform" />
      <MobileMenu />
      <HeaderStyleNine headerType="" />
      <div
        className="w-100 mt-5"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {flow ? (
          <div
            className="shadow-lg p-5 bg-white rounded"
            style={{ width: "500px" }}
          >
            <div className="w-[80%] mx-auto text-[18px]">
              <h2 className="w-[90%] font-bold text-[18px]">
                Login Your Account
              </h2>
              <p className="text-gray-400" style={{ marginTop: "-20px" }}>
                Provide details to login
              </p>

              {loginMessage
                ? loginMessage?.map((msg) => (
                  <div
                    key={msg?.text}
                    className="alert alert-danger"
                    role="alert"
                  >
                    {msg?.text}
                  </div>
                ))
                : null}
              <div className="w-full">
                <Formik
                  onSubmit={handleSubmit}
                  validationSchema={validation}
                  initialValues={{
                    email: "",
                    password: "",
                    checkbox: "",
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    touched,
                    handleSubmit,
                  }) => (
                    <form
                      className=" flex flex-col items-center w-full"
                      onSubmit={handleSubmit}
                    >
                      <div className=" w-full">
                        <label className="font-bold text-[#292828e7]">
                          Email Address
                        </label>
                        <input
                          name="email"
                          type="email"
                          value={values?.email}
                          placeholder="Enter Your Email Address"
                          className=" px-4 py-4 w-full my-2 outline-none"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {errors.email && touched && touched?.email && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {errors.email}
                          </p>
                        )}
                        <label className="font-bold text-[#292828e7]">
                          Password
                        </label>
                        <div
                          className="relative w-full mt-1"
                          style={{ position: "relative" }}
                        >
                          <input
                            type={inputType}
                            placeholder="Password"
                            name="password"
                            value={values?.password}
                            className=" px-4 py-4 w-full relative outline-none"
                            id="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {errors.password && touched && touched?.password && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {errors.password}
                            </p>
                          )}
                          {!isActive ? (
                            <FaEyeSlash
                              size={20}
                              className=" cursor-pointer absolute  right-5"
                              onClick={handlePassword}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "15px",
                              }}
                            />
                          ) : (
                            <FaEye
                              size={20}
                              className=" cursor-pointer absolute top-3 right-5"
                              onClick={handlePassword}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "15px",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className="d-flex gap-2 w-100 my-3"
                        style={{ alignItems: "center" }}
                      >
                        <input
                          type="checkbox"
                          name="checkbox"
                          value={values?.checkbox}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className=""
                          style={{
                            opacity: "1",
                            position: "relative",
                            padding: "0",
                            width: "20px",
                          }}
                        />
                        <p className="">
                          I Accept Mentorbliss <Link href="/terms" className="text-primary">Terms</Link> And <Link href="/privacy" className="text-primary">Condition</Link>
                        </p>
                      </div>
                      {errors.checkbox && touched && touched?.checkbox && (
                        <p style={{ color: "red", marginBottom: "0px" }}>
                          {errors.checkbox}
                        </p>
                      )}
                      <p>
                        <Link
                          href="/recovery"
                          className="text-[orange] cursor-pointer"
                        >
                          Forgot password?
                        </Link>
                      </p>
                      <button
                        disabled={loginLoading}
                        type="submit"
                        className="bg-primary text-center rounded-2 w-100 text-white p-4 mb-4 border-0"
                      >
                        {loginLoading ? <Spinner /> : `Login`}
                      </button>
                      <p>
                        Don&apos;t have an account?{" "}
                        <Link
                          href="/create-account"
                          className="text-[orange] cursor-pointer"
                        >
                          Create Account
                        </Link>
                      </p>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        ) : (
          <Spinner className={`text-primary `} />
        )}
      </div>
      {/* <Marketplace /> */}
      {/* <Cart /> */}

      {/* <BackToTop /> */}
      {/* <Separator /> */}
      <FooterThree />
    </>
  );
};

export default Account;

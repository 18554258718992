import React, { useCallback, useEffect, useState } from "react";
import { SetLoginUriFlow } from "@/pkg/ory/helpers";
import { HandelErrorMessages, HandleError } from "@/pkg/ory/hooks";
import Router, { useRouter } from "next/router";
import { identity } from "@/pkg/ory/sdk";

export const useLogin = () => {
  const [flow, setFlow] = useState(null);
  const [csrf_token, setCsrf_token] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const router = useRouter();

  const returnTo = String(router.query.return_to || "");
  const flowId = String(router.query.flow || "");

  // Refresh means we want to refresh the session. This is needed, for example, when we want to update the password
  // of a user.
  const refresh = Boolean(router.query.refresh);

  // AAL = Authorization Assurance Level. This implies that we want to upgrade the AAL, meaning that we want
  // to perform two-factor authentication/verification.
  const aal = String(router.query.aal || "");

  const getFlow = useCallback(
    async (id) =>
      // If ?flow=.. was in the URL, we fetch it
      await identity
        .getLoginFlow({ id })
        .then(({ data }) => {
          setFlow(data);
        })
        .catch((err) => {
          const errorMessage = HandelErrorMessages(err)
          setMessage(errorMessage)
        }),
    []
  );

  const createFlow = useCallback(
    async (refresh, aal, returnTo) =>
      await identity
        .createBrowserLoginFlow({
          refresh: refresh,
          // Check for two-factor authentication
          aal: aal,
          returnTo: returnTo,
        })
        .then(({ data }) => {
          setFlow(data);
          SetLoginUriFlow(Router, data.id);
          const csrf = data.ui.nodes.find(
            (csr) => csr.attributes.name === "csrf_token"
          );
          setCsrf_token(csrf.attributes.value);
        })
        .catch((err) => {
          const errorMessage = HandelErrorMessages(err)
          setMessage(errorMessage)
          // console.log(err);
        }),
    []
  );

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (flowId) {
      getFlow(flowId).catch(() => {
        createFlow(refresh, aal, returnTo);
      });
      return;
    }

    // Otherwise we initialize it
    createFlow(refresh, aal, returnTo);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  const submitFlow = async (values) => {
    setLoading(true);
    await identity
      .updateLoginFlow({
        flow: String(flow?.id),
        updateLoginFlowBody: values,
      })
      // We logged in successfully! Let's bring the user home.
      .then(() => {
        if (flow?.return_to) {
          window.location.href = flow?.return_to;
          return;
        }
        setLoading(false);
        Router.push("/student/student-dashboard");
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = HandelErrorMessages(err);
        setMessage(errorMessage);
      });
  };


  return {
    flow,
    submitFlow,
    csrf_token,
    loginLoading: loading,
    loginMessage: message,
  };
};
